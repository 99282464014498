import { COMPONENT_TYPES } from '../../../../constants/component-types'

type FieldsLayoutConfigValue = {
  supportedValues?: string[]
  key: string
}

export type FieldsLayoutConfig = { [key in COMPONENT_TYPES]?: FieldsLayoutConfigValue }

const DIRECTION = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
}

const TEXT_ALIGNMENT_KEYS = {
  ALIGNMENT: 'alignment',
  TEXT_ALIGNMENT: 'textAlignment',
  LABEL_ALIGNMENT: 'labelAlignment',
  FILES_ALIGNMENT: 'filesAlignment',
}

export const TEXT_ALIGNMENT: FieldsLayoutConfig = {
  [COMPONENT_TYPES.RADIO_GROUP]: {
    key: TEXT_ALIGNMENT_KEYS.ALIGNMENT,
    supportedValues: [DIRECTION.LEFT, DIRECTION.RIGHT],
  },
  [COMPONENT_TYPES.SINGLE_CHECKBOX]: {
    key: TEXT_ALIGNMENT_KEYS.ALIGNMENT,
    supportedValues: [DIRECTION.LEFT, DIRECTION.RIGHT],
  },
  [COMPONENT_TYPES.CHECKBOX_GROUP]: {
    key: TEXT_ALIGNMENT_KEYS.ALIGNMENT,
    supportedValues: [DIRECTION.LEFT, DIRECTION.RIGHT],
  },
  [COMPONENT_TYPES.TEXT_INPUT]: {
    key: TEXT_ALIGNMENT_KEYS.TEXT_ALIGNMENT,
    supportedValues: [DIRECTION.LEFT, DIRECTION.CENTER, DIRECTION.RIGHT],
  },
  [COMPONENT_TYPES.TEXT_AREA_INPUT]: {
    key: TEXT_ALIGNMENT_KEYS.TEXT_ALIGNMENT,
    supportedValues: [DIRECTION.LEFT, DIRECTION.CENTER, DIRECTION.RIGHT],
  },
  [COMPONENT_TYPES.DATE_PICKER]: {
    key: TEXT_ALIGNMENT_KEYS.TEXT_ALIGNMENT,
    supportedValues: [DIRECTION.LEFT, DIRECTION.CENTER, DIRECTION.RIGHT],
  },
  [COMPONENT_TYPES.RATING]: {
    key: TEXT_ALIGNMENT_KEYS.LABEL_ALIGNMENT,
    supportedValues: [DIRECTION.LEFT, DIRECTION.CENTER, DIRECTION.RIGHT],
  },
  [COMPONENT_TYPES.COMBOBOX]: {
    key: TEXT_ALIGNMENT_KEYS.TEXT_ALIGNMENT,
    supportedValues: [DIRECTION.LEFT, DIRECTION.CENTER, DIRECTION.RIGHT],
  },
  [COMPONENT_TYPES.FILE_UPLOADER]: {
    key: TEXT_ALIGNMENT_KEYS.FILES_ALIGNMENT,
    supportedValues: [DIRECTION.LEFT, DIRECTION.CENTER, DIRECTION.RIGHT],
  },
}

const LABEL_PADDING_KEY = 'labelPadding'

export const LABEL_PADDING: FieldsLayoutConfig = {
  [COMPONENT_TYPES.TEXT_INPUT]: {
    key: LABEL_PADDING_KEY,
  },
  [COMPONENT_TYPES.TEXT_AREA_INPUT]: {
    key: LABEL_PADDING_KEY,
  },
  [COMPONENT_TYPES.DATE_PICKER]: {
    key: LABEL_PADDING_KEY,
  },
  [COMPONENT_TYPES.COMBOBOX]: {
    key: LABEL_PADDING_KEY,
  },
  [COMPONENT_TYPES.FILE_UPLOADER]: {
    key: LABEL_PADDING_KEY,
  },
}

const LABEL_MARGIN_KEY = 'labelMargin'

export const LABEL_MARGIN: FieldsLayoutConfig = {
  [COMPONENT_TYPES.RADIO_GROUP]: {
    key: LABEL_MARGIN_KEY,
  },
  [COMPONENT_TYPES.CHECKBOX_GROUP]: {
    key: LABEL_MARGIN_KEY,
  },
  [COMPONENT_TYPES.TEXT_INPUT]: {
    key: LABEL_MARGIN_KEY,
  },
  [COMPONENT_TYPES.TEXT_AREA_INPUT]: {
    key: LABEL_MARGIN_KEY,
  },
  [COMPONENT_TYPES.DATE_PICKER]: {
    key: LABEL_MARGIN_KEY,
  },
  [COMPONENT_TYPES.COMBOBOX]: {
    key: LABEL_MARGIN_KEY,
  },
  [COMPONENT_TYPES.FILE_UPLOADER]: {
    key: LABEL_MARGIN_KEY,
  },
}

const TEXT_PADDING_KEYS = {
  TEXT_PADDING: 'textPadding',
  SPACING: 'spacing',
}

export const TEXT_PADDING: FieldsLayoutConfig = {
  [COMPONENT_TYPES.SINGLE_CHECKBOX]: {
    key: TEXT_PADDING_KEYS.SPACING,
  },
  [COMPONENT_TYPES.TEXT_INPUT]: {
    key: TEXT_PADDING_KEYS.TEXT_PADDING,
  },
  [COMPONENT_TYPES.TEXT_AREA_INPUT]: {
    key: TEXT_PADDING_KEYS.TEXT_PADDING,
  },
  [COMPONENT_TYPES.DATE_PICKER]: {
    key: TEXT_PADDING_KEYS.TEXT_PADDING,
  },
  [COMPONENT_TYPES.COMBOBOX]: {
    key: TEXT_PADDING_KEYS.TEXT_PADDING,
  },
}

export const FIELDS_THAT_SUPPORT_SHOWING_LABEL = [
  COMPONENT_TYPES.RADIO_GROUP,
  COMPONENT_TYPES.CHECKBOX_GROUP,
  COMPONENT_TYPES.TEXT_INPUT,
  COMPONENT_TYPES.TEXT_AREA_INPUT,
  COMPONENT_TYPES.DATE_PICKER,
  COMPONENT_TYPES.COMBOBOX,
  COMPONENT_TYPES.SIGNATURE_INPUT,
  COMPONENT_TYPES.FILE_UPLOADER,
]
