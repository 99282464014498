import translations from '../../../utils/translations'
import * as _ from 'lodash'
import { GFPP, GFPP_ACTIONS, GFPP_IDS } from './manifest-commons'
import { AppStateObject } from '../app-state/app-state-builder'
import { FormPlugin } from '../../../constants/plugins'
import { createGlobalDesignManifest, BASE_DESIGN_GROUPS } from './global-design-manifest'
import { COMPONENT_TYPES } from '../../../constants/component-types'

const getDisplayName = (plugins: FormPlugin[] = []) => {
  if (isRegistrationForm(plugins)) {
    return 'registrationFormBuilder'
  } else if (isGetSubscribersForm(plugins)) {
    return 'getSubscribersFormBuilder'
  }

  return 'formBuilder'
}

const isRegistrationForm = (plugins: FormPlugin[] = []) =>
  _.includes(plugins, FormPlugin.REGISTRATION_FORM)

const isGetSubscribersForm = (plugins: FormPlugin[] = []) =>
  _.includes(plugins, FormPlugin.GET_SUBSCRIBERS)

const getHelpId = (plugins: FormPlugin[] = []) => {
  return _.includes(plugins, FormPlugin.REGISTRATION_FORM)
    ? GFPP.HELP_ID.REGISTRATION_FORM_CONTAINER
    : GFPP.HELP_ID.FORM_CONTAINER
}

const getGfppActions = ({ replaceManageFieldsWithAddFieldGffp, extraIconButtons }) => {
  const iconButtons = {
    [GFPP_ACTIONS.LAYOUT]: {
      actionId: GFPP_IDS.FORM_LAYOUT,
    },
    [GFPP_ACTIONS.ANIMATION]: GFPP.KEEP_DEFAULT,
    [GFPP_ACTIONS.CONNECT]: GFPP.REMOVE,
    [GFPP_ACTIONS.ADD]: { actionId: GFPP_IDS.ADD_FIELD },
    ...extraIconButtons,
  }

  const mainAction1 = {
    actionId: GFPP_IDS.NEW_FORM_SETTINGS,
    label: translations.t('formSettings.gfppTitle'),
  }

  const mainAction2 = replaceManageFieldsWithAddFieldGffp
    ? {
        actionId: GFPP_IDS.ADD_FIELD,
        label: translations.t('addField.gfppTitle'),
      }
    : {
        actionId: GFPP_IDS.MANAGE_FIELDS,
        label: translations.t('manageFields.gfppTitle'),
      }

  return { mainAction1, mainAction2, iconButtons }
}

export const createFormContainerManifest = ({
  isTopPremium,
  plugins,
  duplicatable = false,
  replaceManageFieldsWithAddFieldGffp = false,
}: AppStateObject = {}) => {
  const displayName = getDisplayName(plugins)
  const extraIconButtons = {
    ...(isTopPremium ? {} : { [GFPP_ACTIONS.UPGRADE]: GFPP.KEEP_DEFAULT }),
  }
  const gloablDesignManifest = {
    gfpp: {
      desktop: {
        globalDesign: {
          ...createGlobalDesignManifest({
            customHelpId: '72d4c414-370d-4bf7-b70c-7a29795bdbda',
            presetHelpId: '56086989-6a7f-400e-985c-d9a42c81c697',
          }),
        },
      },
    },
    designMapping: {
      [COMPONENT_TYPES.FORM_CONTAINER]: {
        [BASE_DESIGN_GROUPS.FORM_BACKGROUND]: ['bg'],
        [BASE_DESIGN_GROUPS.FORM_BORDER_COLOR]: ['brd'],
        [BASE_DESIGN_GROUPS.FORM_BORDER_SIZE]: ['brw'],
        [BASE_DESIGN_GROUPS.FORM_CORNERS]: ['rd'],
        [BASE_DESIGN_GROUPS.FORM_SHADOW]: ['shd'],
      },
    },
  }

  const { mainAction1, mainAction2, iconButtons } = getGfppActions({
    extraIconButtons,
    replaceManageFieldsWithAddFieldGffp,
  })

  const manifest = {
    showFocusBox: true,
    displayName: translations.t(displayName),
    behavior: { duplicatable },
    gfpp: {
      desktop: {
        mainAction1,
        mainAction2,
        iconButtons,
        helpId: getHelpId(plugins),
      },
      mobile: {
        /* use default configurations */
        helpId: GFPP.HELP_ID.FORM_CONTAINER_MOBILE,
      },
    },
  }
  return { box: _.merge(manifest, gloablDesignManifest), steps: null }
}
